import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import http from '@/services/http'

@Component
export default class Dashboard extends Vue {
  loading = false
  data: Array<any> = []
  mounted() {
    this.getNumberMember()
  }
  async getNumberMember() {
    try {
      const res = await http.get('/api/crm/home/')
      if (!res.data) throw new Error(res.data)
      for (const property in res.data) {
        //this.data.push(`${property}: ${res.data[property]}`)
        this.data.push(this.item(property, res.data[property]))
      }
      setTimeout(() => {
        this.loading = true
      }, 1000)
    } catch (error) {
      //console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  item(itemKey: string, itemValue: number) {
    const item = {
      key: itemKey,
      value: itemValue,
    }
    return item
  }
}
